import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container, Grid, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import establishmentBkg from "../../assets/placeholders/establishment.jpg";
import KCButtonBase from '../../components/Button';
import KCStarRating from '../../components/KCStarRating';
import KCToggleGroup from '../../components/KCToggleGroup';
import { Establishment, useGetPublicEstablishmentWithIdQuery, useMarkResourceAsViewedMutation } from '../../store/kcApi';
import { RootState } from '../../store/store';
import { ScrollToTopOnMount } from '../ScrollToTopOnMount';
import Benefits from './component/Benefits';
import Culture from './component/Culture';
import MiniLocation from './component/MiniLocation';
import OpenPositions from './component/OpenPositions/index';
import CustomStats, { CustomStatsProps, StatsComparisonType } from './component/QuickStats';
import { PrivateReviews, PublicReviews } from './component/Reviews';
import NoiseAnimatedBackground from "../ExplorePage/components/NoiseAnimatedBackground";

const ResponsiveContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box>
        {children}
      </Box>
    );
  }

  return (
    <Container>
      {children}
    </Container>
  );
};

const EstablishmentPage: React.FC = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isAuthenticated, isLoading: authLoading } = useAuth0();
  const currentUser = useSelector((state: RootState) => state.auth);
  const [userAuthReady, setUserAuthReady] = useState<boolean>(false);
  const [markResourceAsViewed] = useMarkResourceAsViewedMutation();
  useEffect(() => {
    if (!authLoading && currentUser.token != null) {
      setUserAuthReady(true);
    }
  }, [authLoading, currentUser]);

  useEffect(() => {
    if (userAuthReady && currentUser.user_id != null) {
      markResourceAsViewed({ userId: currentUser.user_id, viewedResource: {
        resource_id: establishmentId,
        resource_type: 'establishment',
      } });
    }
  }, [userAuthReady, currentUser.user_id]);

  // get the establishment id from the url
  const { establishmentId } = useParams<{ establishmentId: string }>();

  let navigate = useNavigate();

  function toTitleCase(str: any) {
    if (!str) return '';
    return str.toLowerCase().split(' ').map((word: any) => {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  const {
    data: establishment,
    isLoading
  } = useGetPublicEstablishmentWithIdQuery({ establishmentId: establishmentId || '' });
  // State to manage the delayed loading state
  const [showLoading, setShowLoading] = useState(isLoading);
  // state for insights data
  const [insightsFilter, setInsightsFilter] = useState<string>("All");
  const [insightsData, setInsightsData] = useState<CustomStatsProps>({
    avgHourlyWage: 0,
    avgWageComparison: StatsComparisonType.UNKNOWN,
    avgTipsPerShift: 0,
    avgTipsComparison: StatsComparisonType.UNKNOWN,
    tipPooling: false,
    avgShiftsPerWeek: 0,
    avgShiftsComparison: StatsComparisonType.UNKNOWN,
    healthScore: '',
    wageTheftsReported: false,
    wageTheftsLink: '',
  });
  const [estLat, setEstLat] = useState<number>(0);
  const [estLng, setEstLng] = useState<number>(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isLoading) {
      // Immediately show loading when isLoading is true
      setShowLoading(true);
    } else {
      // Delay the removal of the loading screen
      timer = setTimeout(() => setShowLoading(false), 500); // Adjust 500ms to your needs
    }

    // Cleanup the timer when the component unmounts or isLoading changes
    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    setInsightsData(getCustomStatsProps(insightsFilter, establishment ?? {}, isLoading));

    if (establishment?.location) {
      if (establishment.location.coordinates) {
        setEstLat(establishment.location.coordinates[1]);
        setEstLng(establishment.location.coordinates[0]);
      }
    }

  }, [insightsFilter, establishment, isLoading]);


  const getCustomStatsProps = (insightsFilter: string | null, establishment: Establishment, isLoading: boolean): CustomStatsProps => {
    let statsData;

    let tipPooling = establishment.insights?.front?.tip_pooling || establishment.insights?.back?.tip_pooling

    if (insightsFilter === 'All') {
      statsData = { ...establishment.insights?.total, ...{ tip_pooling: tipPooling } }
    } else if (insightsFilter === 'Front of House' || insightsFilter === 'FOH') {
      statsData = establishment.insights?.front;
    } else if (insightsFilter === 'Back of House' || insightsFilter === 'BOH') {
      statsData = establishment.insights?.back;
    }

    // return the CustomStatsProps based on the insightsFilter and establishmentInsights data
    return {
      avgHourlyWage: statsData?.avg_hourly_wage || 0,
      avgWageComparison: statsData?.avg_hourly_wage_near_by as StatsComparisonType || StatsComparisonType.UNKNOWN,
      avgTipsPerShift: statsData?.avg_tip_take || 0,
      avgTipsComparison: statsData?.avg_tip_take_near_by as StatsComparisonType || StatsComparisonType.UNKNOWN,
      tipPooling: statsData?.tip_pooling || false,
      avgShiftsPerWeek: statsData?.avg_shifts_per_week || 0,
      avgShiftsComparison: statsData?.avg_shifts_per_week_near_by as StatsComparisonType || StatsComparisonType.UNKNOWN,
      healthScore: establishment.health_score || 'Unknown',
      wageTheftsReported: establishment.wage_theft_reported || false,
      wageTheftsLink: establishment.wage_theft_link || '',
      isLoading: isLoading,
    };
  };

  let overallRating = establishment?.insights?.total?.overall_rating;

  // Check if overallRating is not undefined
  if (overallRating !== undefined) {
    // Convert to a decimal with one place and back to a number
    overallRating = parseFloat(overallRating.toFixed(1));
  }

  return (
    <Box sx={{ backgroundColor: "#FBF8F5", paddingBottom: '60px', position: 'relative', zIndex: 0 }}>
      <NoiseAnimatedBackground />
      <ScrollToTopOnMount />
      {/* {isLoading ? <Loading /> : (
      <> */}
      <Box sx={{ position: 'relative' }}>
        <Box
          component="img"
          sx={{
            backgroundImage: `url(${establishment?.photo || ''})`,
            opacity: 0.1,
            width: '100%',
            height: '85%',
            filter: 'blur(6px)',
            position: 'absolute',
            top: 64
          }}
          alt="Background image with filter"
        />
        <ResponsiveContainer>
          <Grid container={!isMobile} sx={{
            padding: "70px 0px",
            [theme.breakpoints.down('sm')]: {
              padding: "20px 0px",
            }
          }} spacing={isMobile ? undefined : 6}>
            <Grid item xs={12} md={4}>
              {showLoading ? <Skeleton variant="rectangular" width={380} height={380} /> : (
                <Box
                  component="img"
                  sx={{
                    height: '380px',
                    width: '380px',
                    borderRadius: "20px",
                    marginLeft: "20px",
                    [theme.breakpoints.down('sm')]: {
                      height: '194px',
                      width: '100%',
                      borderRadius: '0px',
                      marginLeft: "0px",
                      // borderRadius: '16px 16px 0px 0px',
                    },

                  }}
                  alt="The house from the offer."
                  src={establishment?.photo || establishmentBkg}
                  className="establishment-img"
                />
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack direction="column" spacing={2} sx={{
                padding: "0px 0px 0px 80px",
                [theme.breakpoints.down('md')]: {
                  padding: "20px",
                }
              }}>
                <Box>
                  <Stack direction="row" spacing={2} alignItems='center'>
                    {isLoading ? <Skeleton width={200} height={40} /> : <KCStarRating value={overallRating} variant='dark' readOnly />}
                    <Typography variant="body2" color="text.secondary">{isLoading ? <Skeleton /> : (overallRating + " stars / " + establishment?.insights?.total?.number_of_reviews + " Ratings")}</Typography>
                  </Stack>
                </Box>
                <Typography variant="h2" color="text.secondary">{isLoading ? <Skeleton /> : establishment?.name}</Typography>
                <Typography variant="body1" color="text.secondary">{isLoading ? <Skeleton /> : establishment?.address}</Typography>
                <Typography variant="body2" color="text.secondary">{isLoading ? <Skeleton /> : toTitleCase(establishment?.cuisine)}</Typography>
                <KCButtonBase sx={{
                  width: '242px',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  }
                }} onClick={
                  () => {
                    ReactGA.event({
                      category: "User Interaction",
                      action: "Click",
                      label: "Write Review",
                    });
                    navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review`)
                  }
                }>
                  Write your review
                </KCButtonBase>
              </Stack>
            </Grid>
          </Grid>
        </ResponsiveContainer>
      </Box>
      <Container>
        {isMobile && <OpenPositions
          isLoading={isLoading}
          estId={establishment?.id || ''}
          estName={establishment?.name}
        />}
        <Box sx={{ padding: "20px 0px" }}>
          <KCToggleGroup
            options={isMobile ? ['All', 'FOH', 'BOH'] : ['All', 'Front of House', 'Back of House']}
            value={insightsFilter}
            changeEvent={value => setInsightsFilter(value)}
            fullWidth={isMobile}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{
            padding: '0px 50px',
            [theme.breakpoints.down('sm')]: {
              padding: '0px 0px 20px 0px',
            }
          }}>
            <CustomStats {...insightsData} />
            {isLoading ? <Skeleton variant="rectangular" width={380} height={380} /> : (
              <MiniLocation lat={estLat} lng={estLng} />
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={4} sx={{ marginBottom: '60px' }}>
              {!isMobile &&
                <OpenPositions
                  isLoading={isLoading}
                  estId={establishment?.id || ''}
                  estName={establishment?.name}
                />}
              {(isAuthenticated && !authLoading && userAuthReady) ? (
                <PrivateReviews
                  isLoading={isLoading || authLoading}
                  establishmentId={establishmentId || ''}
                  totalReviews={establishment?.insights?.total?.number_of_reviews || 0}
                  establishmentName={establishment?.name || ""}
                  isAuthenticated={isAuthenticated}
                />
              ) : (
                <PublicReviews
                  isLoading={isLoading || authLoading}
                  establishmentId={establishmentId || ''}
                  totalReviews={establishment?.insights?.total?.number_of_reviews || 0}
                  establishmentName={establishment?.name || ""}
                  isAuthenticated={isAuthenticated}
                />
              )}

              {((establishment?.insights?.total?.number_of_reviews || 0) > 0) && (
                <Culture isLoading={isLoading} analytics={establishment?.insights?.total} />
              )}
              {((establishment?.insights?.total?.number_of_reviews || 0) > 0) && (
                <Benefits isLoading={isLoading} benefits={establishment?.insights?.total?.benefits} />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default EstablishmentPage;

