import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Divider, IconButton, Stack, styled } from '@mui/material';
import { Search } from '@mui/icons-material';

const KCSearchFieldWithIconStyled = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: "48px",
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const KCSearchFieldWithIconStyledOutlined = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    '&:hover fieldset': {
      borderColor: '#837A78',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 16px',
    },
  },
}));

type KCSearchFieldWithIconProps = TextFieldProps & {
  outlined: boolean;
  onSubmit: () => void;
  onClear?: () => void;
};


const KCSearchFieldWithIcon: React.FC<KCSearchFieldWithIconProps> = ({ outlined, onSubmit, onClear, ...props }) => {

  const [searchInput, setSearchInput] = React.useState(props.value || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  }

  const handleClear = () => {
    setSearchInput('');
    // if (props.onChange) {
    //   props.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
    // }
    // onSubmit();
    if (onClear) onClear();
  };

  return (
    !outlined ? (
      <KCSearchFieldWithIconStyled
        {...props}
        variant="outlined"
        value={searchInput}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <Stack direction="row" divider={<Divider />}>
              <Button onClick={handleClear} sx={{ color: 'text.secondary', textTransform: 'none' }}>
                Clear
              </Button>
              <IconButton onClick={() => onSubmit()}>
                <Search />
              </IconButton>
            </Stack>
          ),
        }}
      />
    ) : (
      <KCSearchFieldWithIconStyledOutlined
        {...props}
        variant="outlined"
        value={searchInput}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <Stack direction="row" divider={<Divider />}>
              {(searchInput !== '') && <Button onClick={handleClear} sx={{ color: 'text.secondary', textTransform: 'none' }}>
                Clear
              </Button>}
              <IconButton onClick={() => onSubmit()}>
                <Search />
              </IconButton>
            </Stack>
          ),
        }}
      />
    )
  );
};

export default KCSearchFieldWithIcon;