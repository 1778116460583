import {
  Alert,
  Box, Container,
  Grid, Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';
import { GetPublicEstablishmentsApiArg, useGetPublicEstablishmentsQuery } from '../../store/kcApi';
import { initMapState, selectCurrentMapState, setMapState } from '../../store/mapSlice';
import { CalculateDistance, isEmpty, isInNYC, useUserLocation } from '../../utils/helpers';
import { ScrollToTopOnMount } from '../ScrollToTopOnMount';
import { EstablishmentFilterCardProps } from './components/EstablishmentFilterCard';
import FilterCards from './components/FilterCards';
import FilterCardModal from './components/FilterCards/FilterCardModal';
import NewPopover from './components/FilterCards/NewPopover';
import Filters from './components/Fiters';
import MapView from './components/MapView';
import NoiseAnimatedBackground from './components/NoiseAnimatedBackground';
import { setFilterState } from '../../store/filterSlice';

const ResponsiveContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box>
        {children}
      </Box>
    );
  }

  return (
    <Container>
      {children}
    </Container>
  );
};

type LocationAlertState = {
  isAlert: boolean;
  isNYC: boolean;
  isDenied: boolean;
};

const ExplorePage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const locationAlertState: LocationAlertState = {
    isAlert: false,
    isNYC: false,
    isDenied: false,
  }

  const dispatch = useDispatch();
  const mapState = useAppSelector(selectCurrentMapState);
  const isListVisible = mapState?.isListVisible;
  const activeHireIcon = mapState?.activeHireIcon;

  const { longitude, latitude } = useUserLocation();
  const [searchParams, setSearchParams] = useState<GetPublicEstablishmentsApiArg>({});
  const [locationAlert, setLocationAlert] = useState<LocationAlertState>(locationAlertState);
  const [search] = useState<string>('');

  const location = useLocation();

  useEffect(() => {
    setSearchParams({});
    dispatch(setFilterState({ name: '' }));
    dispatch(initMapState());
    
    if (search) {
      window.history.replaceState({}, '', `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/explore`);
    }
  }, [location.key]);

  const queryArg = useMemo(() => {
    if (activeHireIcon) return {
      isHiring: true,
      ...searchParams,
    }; else {
      return {
        reviewsLow: 1,
        ...searchParams,
      }
    }
  }, [searchParams, activeHireIcon]);
  const {
    data: establishments,
    error: estErr,
    isFetching
  } = useGetPublicEstablishmentsQuery(queryArg, { skip: isEmpty(searchParams) });
  const estLoading = isFetching || isEmpty(searchParams);
  const handleToggleHiring = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMapState({ activeHireIcon: event.target.checked }));
  };

  useEffect(() => {
    dispatch(setMapState({ isListVisible: !isMobile }))
  }, [isMobile, dispatch])

  useEffect(() => {
    const { northEastLatitude, northEastLongitude, southWestLatitude, southWestLongitude } = mapState?.bounds || {};
    const lockBounds = mapState?.lockBounds || false;

    if (lockBounds === false) setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      northEastLatitude,
      northEastLongitude,
      southWestLatitude,
      southWestLongitude,
    }));
  }, [mapState?.bounds, mapState?.lockBounds]);

  useEffect(() => {
    const locationAccess = localStorage.getItem("locationAccess");
    
    if (locationAccess === "false") {
      setLocationAlert({
        isAlert: true,
        isNYC: false,
        isDenied: true,
      });
      return;
    } 

    if (!latitude || !longitude) {
      return;
    }

    const isInsideFn = async () => {
      const isInside = await isInNYC({ latitude: latitude, longitude: longitude });
      isInside ? setLocationAlert({
        isAlert: false,
        isNYC: true,
        isDenied: false,
      }) : setLocationAlert({
        isAlert: true,
        isNYC: false,
        isDenied: false,
      });
    };

    isInsideFn();
  }, [latitude, longitude]);

  useEffect(() => {
    if (estErr) {
      console.log("estErr", estErr)
    }
    if (!establishments?.data) {
      dispatch(setMapState({
        searchedEstablishments: [],
        visibleEstablishments: [],
        visibleCardData: []
      }));
    }
    if (establishments?.data && !estErr && !estLoading) {
      const searchedEstablishments = establishments?.data || [];
      const visibleEstablishments = searchedEstablishments/*.filter(
        item => activeHireIcon ? item.is_hiring : item.total_reviews && item.total_reviews !== 0)*/;
      const visibleCardData = visibleEstablishments.map((item: any): EstablishmentFilterCardProps => (
        {
          estId: item.id || "",
          name: item.name || "",
          address: item.address || "",
          baseRate: item.base_rate || 0,
          avgTips: item.avg_tips || 0,
          distance: CalculateDistance(
            { latitude: latitude, longitude: longitude },
            { latitude: item.location?.coordinates?.[1] || 0, longitude: item.location?.coordinates?.[0] || 0 },
          ),
          sanitationScore: item?.health_score === "Not Yet Graded" ? "--" : item?.health_score || "--",
          image: item.photo || "",
          starRating: item.overall_rating || 0,
          numOfReviews: item.total_reviews || 0,
          isHiring: item.is_hiring || false,
          gid: item.google_place_id || "",
        }
      )) || [];
      dispatch(setMapState({
        searchedEstablishments,
        visibleEstablishments,
        visibleCardData
      }));
    }
  }, [establishments, estErr, estLoading, activeHireIcon, latitude, longitude, dispatch]);

  const hiringSwitchBoxRef = useRef(0);

  return (
    <Box sx={{
      backgroundColor: '#FBF8F5',
      position: 'relative',
      zIndex: 0,
    }}>
      <NoiseAnimatedBackground />
      <ScrollToTopOnMount />
      <ResponsiveContainer>
        <Stack>
          <Container>
            <Typography variant={isMobile ? 'h5' : 'h1'} py={!isMobile ? 8 : 0} textAlign='center'>Explore</Typography>
            {!isMobile && locationAlert.isAlert && (
              <Alert severity="info"
                sx={{
                  margin: "20px 0px",
                }}>
                {locationAlert.isDenied ? "Location access denied. Enable location access to see establishments near you." : "Looks like your location is outside of NYC! We are currently only serving NYC establishments."}
              </Alert>
            )}
            {
              isMobile && (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    justifyContent="center"
                    ref={hiringSwitchBoxRef}
                  >
                    <Switch
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          '&.Mui-checked': {
                            '& + .MuiSwitch-track': {
                              backgroundColor: '#9747FF'
                            },
                            '& .MuiSwitch-thumb': {
                              backgroundColor: '#9747FF'
                            }
                          }
                        },
                      }}
                      checked={activeHireIcon}
                      onChange={handleToggleHiring}
                    />
                    <Typography variant="body1" color={!activeHireIcon ? "text.secondary" : "#9747FF"} fontWeight={600}>Hiring now</Typography>
                  </Box>
                  <NewPopover anchorEl={hiringSwitchBoxRef.current} />
                </>
              )
            }
          </Container>
          <Filters fieldValue={search} setSearchParams={setSearchParams} />
          <Grid container>
            {!(isListVisible && isMobile) &&
              <Grid item xs={12} md={isListVisible ? 6 : 12}>
                <MapView />
              </Grid>
            }
            {
              isListVisible &&
              <Grid item xs={12} md={6}>
                <FilterCards isLoading={estLoading} />
              </Grid>
            }
          </Grid>
          <FilterCardModal isLoading={estLoading} />
        </Stack>
      </ResponsiveContainer>
    </Box>
  );
};

export default ExplorePage;
