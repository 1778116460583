import { useEffect, useState } from 'react';


export interface LocalReview {
  id: string;
  estId: string;
  status: string;
}

export function useUserLocation() {
  const initalCoordinates = {
    longitude: 0,
    latitude: 0
  };
  const [coordinates, setCoordinates] = useState(initalCoordinates);

  useEffect(() => {
    // if (!localStorage.getItem("locationAccess")) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude
        });
        localStorage.setItem("locationAccess", "true");
      }, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  return coordinates;
}

function showError(error: GeolocationPositionError) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      localStorage.setItem("locationAccess", "false");
      break;
  }
}

export type DistanceCoordinate = {
  latitude: number;
  longitude: number;
};

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export function CalculateDistance(coord1: DistanceCoordinate, coord2: DistanceCoordinate): number {

  if (coord1.latitude === 0 && coord1.longitude === 0) {
    return 0;
  }

  const earthRadiusMiles = 3959; // Earth radius in miles

  const dLat = toRadians(coord2.latitude - coord1.latitude);
  const dLon = toRadians(coord2.longitude - coord1.longitude);

  const lat1 = toRadians(coord1.latitude);
  const lat2 = toRadians(coord2.latitude);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadiusMiles * c;
}

export async function isInNYC(coord: DistanceCoordinate) {
  const apiKey = 'AIzaSyCNVmTF4f5lgJSkhUDJY4q4PeDIl-d6mkY';
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coord.latitude},${coord.longitude}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data.results.some((item: any) => {
      const addressComponents = item.address_components;
      for (const component of addressComponents) {
        if (component.types[0] === "locality" && component.types[1] === 'political' && component.long_name === "New York") {
          return true;
        }
      }
      return false;
    });
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function getPositionWageInfo(position: any): string {
  if (position == null) return "";
  return position.wageValue?.toString() === '0E-6176' ? `$${position.minWageValue} - $${position.maxWageValue}` : `$${position.wageValue}`;
}

export function isEmpty(value: any) {
  return value === null || value === undefined ||
    (typeof value === 'object' &&
      (Object.keys(value).length === 0 || Object.values(value).every(v => v === undefined))) ||
    (Array.isArray(value) && value.length === 0);
}