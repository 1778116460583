import { kcApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEstablishmentReviews: build.query<
      GetEstablishmentReviewsApiResponse,
      GetEstablishmentReviewsApiArg
    >({
      query: (queryArg) => ({
        url: `/establishment/${queryArg.establishmentId}/reviews`,
        params: { limit: queryArg.limit, page: queryArg.page },
      }),
    }),
    getPublicEstablishments: build.query<
      GetPublicEstablishmentsApiResponse,
      GetPublicEstablishmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/public/establishment`,
        params: {
          text_location: queryArg.textLocation,
          radius: queryArg.radius,
          name: queryArg.name,
          wage_high: queryArg.wageHigh,
          wage_low: queryArg.wageLow,
          tips_high: queryArg.tipsHigh,
          tips_low: queryArg.tipsLow,
          health_score: queryArg.healthScore,
          overall_rating_low: queryArg.overallRatingLow,
          benefits: queryArg.benefits,
          role_position: queryArg.rolePosition,
          reviews_low: queryArg.reviewsLow,
          reviews_high: queryArg.reviewsHigh,
          center_longitude: queryArg.centerLongitude,
          center_latitude: queryArg.centerLatitude,
          north_east_longitude: queryArg.northEastLongitude,
          north_east_latitude: queryArg.northEastLatitude,
          south_west_longitude: queryArg.southWestLongitude,
          south_west_latitude: queryArg.southWestLatitude,
          zipcode: queryArg.zipcode,
          city: queryArg.city,
          state: queryArg.state,
          neighborhood: queryArg.neighborhood,
          is_hiring: queryArg.isHiring,
          limit: queryArg.limit,
          page: queryArg.page,
        },
      }),
    }),
    createPublicEstablishment: build.mutation<
      CreatePublicEstablishmentApiResponse,
      CreatePublicEstablishmentApiArg
    >({
      query: (queryArg) => ({
        url: `/public/establishment`,
        method: "POST",
        body: queryArg.createEstablishment,
      }),
    }),
    getPublicEstablishmentWithId: build.query<
      GetPublicEstablishmentWithIdApiResponse,
      GetPublicEstablishmentWithIdApiArg
    >({
      query: (queryArg) => ({
        url: `/public/establishment/${queryArg.establishmentId}`,
      }),
    }),
    getPublicEstablishmentReviews: build.query<
      GetPublicEstablishmentReviewsApiResponse,
      GetPublicEstablishmentReviewsApiArg
    >({
      query: (queryArg) => ({
        url: `/public/establishment/${queryArg.establishmentId}/reviews`,
        params: { limit: queryArg.limit, page: queryArg.page },
      }),
    }),
    getPublicEstablishmentJobListings: build.query<
      GetPublicEstablishmentJobListingsApiResponse,
      GetPublicEstablishmentJobListingsApiArg
    >({
      query: (queryArg) => ({
        url: `/public/establishment/${queryArg.establishmentId}/job-listings`,
        params: {
          limit: queryArg.limit,
          page: queryArg.page,
          status: queryArg.status,
          expiration_date: queryArg.expirationDate,
        },
      }),
    }),
    getPublicEstablishmentSearch: build.query<
      GetPublicEstablishmentSearchApiResponse,
      GetPublicEstablishmentSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/public/establishment/search`,
        params: {
          text_search: queryArg.textSearch,
          longitude: queryArg.longitude,
          latitude: queryArg.latitude,
          city: queryArg.city,
          state: queryArg.state,
          expanded: queryArg.expanded,
          limit: queryArg.limit,
          page: queryArg.page,
        },
      }),
    }),
    getReviewRoles: build.query<
      GetReviewRolesApiResponse,
      GetReviewRolesApiArg
    >({
      query: () => ({ url: `/public/review/roles` }),
    }),
    createPublicReview: build.mutation<
      CreatePublicReviewApiResponse,
      CreatePublicReviewApiArg
    >({
      query: (queryArg) => ({
        url: `/public/review`,
        method: "POST",
        body: queryArg.createUpdateReview,
      }),
    }),
    updatePublicReview: build.mutation<
      UpdatePublicReviewApiResponse,
      UpdatePublicReviewApiArg
    >({
      query: (queryArg) => ({
        url: `/public/review/${queryArg.reviewId}`,
        method: "PATCH",
        body: queryArg.createUpdateReview,
      }),
    }),
    getPublicReviewWithId: build.query<
      GetPublicReviewWithIdApiResponse,
      GetPublicReviewWithIdApiArg
    >({
      query: (queryArg) => ({ url: `/public/review/${queryArg.reviewId}` }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/user`,
        method: "POST",
        body: queryArg.user,
      }),
    }),
    getUserWithId: build.query<GetUserWithIdApiResponse, GetUserWithIdApiArg>({
      query: (queryArg) => ({ url: `/user/${queryArg.userId}` }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}`,
        method: "PUT",
        body: queryArg.updateUser,
      }),
    }),
    resetUserPassword: build.mutation<
      ResetUserPasswordApiResponse,
      ResetUserPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/reset-password`,
        method: "POST",
      }),
    }),
    getUserSavedJobListings: build.query<
      GetUserSavedJobListingsApiResponse,
      GetUserSavedJobListingsApiArg
    >({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/job-listings`,
        params: { limit: queryArg.limit, page: queryArg.page },
      }),
    }),
    deleteUserSavedJobListings: build.mutation<
      DeleteUserSavedJobListingsApiResponse,
      DeleteUserSavedJobListingsApiArg
    >({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/job-listings`,
        method: "DELETE",
        body: queryArg.saveJobListing,
      }),
    }),
    postUserSavedJobListings: build.mutation<
      PostUserSavedJobListingsApiResponse,
      PostUserSavedJobListingsApiArg
    >({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/job-listings`,
        method: "POST",
        body: queryArg.saveJobListing,
      }),
    }),
    markResourceAsViewed: build.mutation<
      MarkResourceAsViewedApiResponse,
      MarkResourceAsViewedApiArg
    >({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/viewed`,
        method: "POST",
        body: queryArg.viewedResource,
      }),
    }),
    getUserReviews: build.query<
      GetUserReviewsApiResponse,
      GetUserReviewsApiArg
    >({
      query: (queryArg) => ({ url: `/user/${queryArg.userId}/reviews` }),
    }),
    createContactUs: build.mutation<
      CreateContactUsApiResponse,
      CreateContactUsApiArg
    >({
      query: (queryArg) => ({
        url: `/promo/email/contactus`,
        method: "POST",
        body: queryArg.emailContactUs,
      }),
    }),
    createSubscription: build.mutation<
      CreateSubscriptionApiResponse,
      CreateSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/promo/email/subscribe`,
        method: "POST",
        body: queryArg.subscription,
      }),
    }),
    getPublicEstablishmentNeighborhoods: build.query<
      GetPublicEstablishmentNeighborhoodsApiResponse,
      GetPublicEstablishmentNeighborhoodsApiArg
    >({
      query: (queryArg) => ({
        url: `/public/neighborhood`,
        params: {
          city: queryArg.city,
          state: queryArg.state,
          limit: queryArg.limit,
          page: queryArg.page,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as kcApi };
export type GetEstablishmentReviewsApiResponse =
  /** status 200 The reviews corresponding to the provided establishment */ KcResponse;
export type GetEstablishmentReviewsApiArg = {
  /** id for a single establishment */
  establishmentId: string;
  /** Limit for page */
  limit?: number;
  /** Page for pagination */
  page?: number;
};
export type GetPublicEstablishmentsApiResponse =
  /** status 200 Collection of establishments with limited information based on the query */ {
    data?: LimitedEstablishment[];
    total_items?: number;
    next_page?: number;
  };
export type GetPublicEstablishmentsApiArg = {
  /** some text to search for establishments by location */
  textLocation?: string;
  /** radius in meters, default is 1600m */
  radius?: number;
  /** name of the establishment */
  name?: string;
  /** high end of wage range */
  wageHigh?: number;
  /** low end of wage range */
  wageLow?: number;
  /** high end of tips range */
  tipsHigh?: number;
  /** low end of tips range */
  tipsLow?: number;
  /** health score of the establishment */
  healthScore?: string;
  /** low end of overall rating range */
  overallRatingLow?: number;
  /** benefits of the establishment */
  benefits?: string[];
  /** position of the user */
  rolePosition?: "front_of_house" | "back_of_house" | "all";
  /** low end of reviews range */
  reviewsLow?: number;
  /** high end of reviews range */
  reviewsHigh?: number;
  /** center point longitude of the user */
  centerLongitude?: number;
  /** center point latitude of the user */
  centerLatitude?: number;
  /** north east longitude of search area */
  northEastLongitude?: number;
  /** north east latitude of search area */
  northEastLatitude?: number;
  /** south west longitude of search area */
  southWestLongitude?: number;
  /** south west latitude of search area */
  southWestLatitude?: number;
  /** zipcode of the establishment */
  zipcode?: string;
  /** city of the establishment */
  city?: string;
  /** state of the establishment, short code such as 'CA' */
  state?: string;
  /** neighborhood of the establishment */
  neighborhood?: string;
  /** The establishment has active and not-expired job listings */
  isHiring?: boolean;
  /** Limit for page */
  limit?: number;
  /** Page for pagination */
  page?: number;
};
export type CreatePublicEstablishmentApiResponse = /** status 201 Created */ {
  id?: string;
};
export type CreatePublicEstablishmentApiArg = {
  /** Request body for creating an establishment */
  createEstablishment: CreateEstablishment;
};
export type GetPublicEstablishmentWithIdApiResponse =
  /** status 200 The establishment corresponding to the provided `establishmentId` */ Establishment;
export type GetPublicEstablishmentWithIdApiArg = {
  /** id for a single establishment */
  establishmentId: string;
};
export type GetPublicEstablishmentReviewsApiResponse =
  /** status 200 The reviews corresponding to the provided establishment */ KcResponse;
export type GetPublicEstablishmentReviewsApiArg = {
  /** id for a single establishment */
  establishmentId: string;
  /** Limit for page */
  limit?: number;
  /** Page for pagination */
  page?: number;
};
export type GetPublicEstablishmentJobListingsApiResponse =
  /** status 200 Collection of job listings for the provided establishment ID */ {
    data?: JobListing[];
    total_items?: number;
    next_page?: number;
  };
export type GetPublicEstablishmentJobListingsApiArg = {
  /** id for a single establishment */
  establishmentId: string;
  /** Limit for page */
  limit?: number;
  /** Page for pagination */
  page?: number;
  /** Status of the job listing */
  status?: string;
  /** Expiration date of the job listing */
  expirationDate?: string;
};
export type GetPublicEstablishmentSearchApiResponse =
  /** status 200 Collection of establishments with limited information based on the query */ {
    data?: LimitedEstablishment[];
    total_items?: number;
    next_page?: number;
  };
export type GetPublicEstablishmentSearchApiArg = {
  /** some text to search for establishments by name */
  textSearch?: string;
  /** longitude of the user */
  longitude?: number;
  /** latitude of the user */
  latitude?: number;
  /** city of the establishment */
  city?: string;
  /** state of the establishment, short code such as 'CA' */
  state?: string;
  /** expanded search via google api */
  expanded?: boolean;
  /** Limit for page */
  limit?: number;
  /** Page for pagination */
  page?: number;
};
export type GetReviewRolesApiResponse =
  /** status 200 The roles for reviews */ ReviewRolesResponse;
export type GetReviewRolesApiArg = void;
export type CreatePublicReviewApiResponse = /** status 201 Created */ {
  id?: string;
};
export type CreatePublicReviewApiArg = {
  /** Request body for creating an review */
  createUpdateReview: CreateUpdateReview;
};
export type UpdatePublicReviewApiResponse =
  /** status 200 The review corresponding to the provided `reviewId` */ CreateUpdateReview;
export type UpdatePublicReviewApiArg = {
  /** id for a single review */
  reviewId: string;
  /** Request body for updating a review */
  createUpdateReview: CreateUpdateReview;
};
export type GetPublicReviewWithIdApiResponse =
  /** status 200 The review corresponding to the provided `reviewId` */ Review;
export type GetPublicReviewWithIdApiArg = {
  /** id for a single review */
  reviewId: string;
};
export type CreateUserApiResponse = /** status 201 Created */ {
  id?: string;
};
export type CreateUserApiArg = {
  /** Request body for creating a user */
  user: User;
};
export type GetUserWithIdApiResponse =
  /** status 200 The user corresponding to the provided `userId` */ User;
export type GetUserWithIdApiArg = {
  /** id for a single user, can be objectid or sub */
  userId: string;
};
export type UpdateUserApiResponse = /** status 200 Updated */ User;
export type UpdateUserApiArg = {
  /** id for a single user, can be objectid or sub */
  userId: string;
  /** Request body for updating a user */
  updateUser: UpdateUser;
};
export type ResetUserPasswordApiResponse =
  /** status 200 Password reset */ KcResponse;
export type ResetUserPasswordApiArg = {
  /** id for a single user, can be objectid or sub */
  userId: string;
};
export type GetUserSavedJobListingsApiResponse =
  /** status 200 Collection of job listings for the provided establishment ID */ {
    data?: JobListing[];
    total_items?: number;
    next_page?: number;
  };
export type GetUserSavedJobListingsApiArg = {
  /** id for a single user, can be objectid or sub */
  userId: string;
  /** Limit for page */
  limit?: number;
  /** Page for pagination */
  page?: number;
};
export type DeleteUserSavedJobListingsApiResponse =
  /** status 204 Job listing was unsaved successfully. */ void;
export type DeleteUserSavedJobListingsApiArg = {
  /** id for a single user, can be objectid or sub */
  userId: string;
  /** Request body to unsave a job listing */
  saveJobListing: SaveJobListing;
};
export type PostUserSavedJobListingsApiResponse =
  /** status 201 Job listing was saved for the specified user. */ void;
export type PostUserSavedJobListingsApiArg = {
  /** id for a single user, can be objectid or sub */
  userId: string;
  /** Request body to save the job listing */
  saveJobListing: SaveJobListing;
};
export type MarkResourceAsViewedApiResponse =
  /** status 200 Resource was marked as viewed */ void;
export type MarkResourceAsViewedApiArg = {
  /** id for a single user, can be objectid or sub */
  userId: string;
  /** Request body to mark a resource as viewed */
  viewedResource: ViewedResource;
};
export type GetUserReviewsApiResponse =
  /** status 200 The reviews corresponding to the provided user */ KcResponse;
export type GetUserReviewsApiArg = {
  /** id for a single user, can be objectid or sub */
  userId: string;
};
export type CreateContactUsApiResponse = /** status 201 Created */ void;
export type CreateContactUsApiArg = {
  /** Request body for creating an contact us email */
  emailContactUs: EmailContactUs;
};
export type CreateSubscriptionApiResponse = /** status 201 Created */ void;
export type CreateSubscriptionApiArg = {
  /** Request body for creating a subscription */
  subscription: Subscription;
};
export type GetPublicEstablishmentNeighborhoodsApiResponse =
  /** status 200 Collection of neighborhoods based on the query */ {
    data?: Neighborhood[];
    total_items?: number;
    next_page?: number;
  };
export type GetPublicEstablishmentNeighborhoodsApiArg = {
  /** city of the establishment */
  city?: string;
  /** state of the establishment, short code such as 'CA' */
  state?: string;
  /** Limit for page */
  limit?: number;
  /** Page for pagination */
  page?: number;
};
export type KcResponse = {
  data?: any;
  total_items?: number;
  limit?: number;
  next_page?: number;
};
export type Error = {
  /** error code */
  code?: number;
  /** A human readable error message */
  message?: string;
};
export type Location = {
  /** type of location, mainly used by mongodb */
  type?: string;
  /** coordinates of the location, mongodb expects longitude first and then latitude. */
  coordinates?: number[];
};
export type LimitedEstablishment = {
  /** primary id for the establishment */
  id?: string;
  /** information about the establishment */
  description?: string;
  /** reference to the google place id used by the google maps api */
  google_place_id?: string;
  /** name of the establishment */
  name?: string;
  /** address of the establishment */
  address?: string;
  /** city of the establishment */
  city?: string;
  /** state of the establishment */
  state?: string;
  /** neighborhood of the establishment */
  neighborhood?: string;
  /** zipcode of the establishment */
  zipcode?: string;
  /** health score of the establishment */
  health_score?: string;
  /** type of establishment, such as 'indian restaurant' */
  type?: string;
  /** url to the cover photo for the establishment. initially taken from google. */
  photo?: string;
  location?: Location;
  /** total number of reviews for the establishment */
  total_reviews?: number;
  /** overall rating of the establishment */
  overall_rating?: number;
  /** base rate of the establishment */
  base_rate?: number;
  /** average tips of the establishment */
  avg_tips?: number;
  /** search score of the establishment */
  search_score?: number;
  /** If the establishment has job listings this is set to true */
  is_hiring?: boolean;
};
export type CreateEstablishment = {
  /** reference to the google place id used by the google maps api */
  google_place_id?: string;
};
export type EstablishmentAnalytics = {
  /** Average hourly wage across the establishment. */
  avg_hourly_wage?: number;
  /** Comparison to near by the establishments */
  avg_hourly_wage_near_by?: "higher" | "lower" | "average";
  /** Average salary wage across the establishment. */
  avg_salary_wage?: number;
  /** Comparison to near by the establishments */
  avg_salary_wage_near_by?: "higher" | "lower" | "average";
  /** Average tip take per shift. */
  avg_tip_take?: number;
  /** Comparison to near by the establishments */
  avg_tip_take_near_by?: "higher" | "lower" | "average";
  /** Average number of shifts per week. */
  avg_shifts_per_week?: number;
  /** Comparison to near by the establishments */
  avg_shifts_per_week_near_by?: "higher" | "lower" | "average";
  /** Average length of each shift in hours. */
  avg_length_of_shift?: number;
  /** Comparison to near by the establishments */
  avg_length_of_shift_near_by?: "higher" | "lower" | "average";
  /** Average cover (customers served) per shift. */
  avg_cover_per_shift?: number;
  /** Comparison to near by the establishments */
  avg_cover_per_shift_near_by?: "higher" | "lower" | "average";
  /** Indicates if the establishment practices tip pooling. */
  tip_pooling?: boolean;
  /** Rating of schedule flexibility. */
  schedule_flexibility?: number;
  /** Comparison to near by the establishments */
  schedule_flexibility_near_by?: "higher" | "lower" | "average";
  /** Employee satisfaction rating. */
  employee_satisfaction?: number;
  /** Comparison to near by the establishments */
  employee_satisfaction_near_by?: "higher" | "lower" | "average";
  /** Management approval rating. */
  management_evaluation?: number;
  /** Comparison to near by the establishments */
  management_evaluation_near_by?: "higher" | "lower" | "average";
  /** Overall rating of the establishment. */
  overall_rating?: number;
  /** Total number of reviews. */
  number_of_reviews?: number;
  /** Total number of ratings. */
  number_of_ratings?: number;
  /** Benefits of working at the establishment. */
  benefits?: {
    [key: string]: number;
  };
};
export type EstablishmentInsights = {
  total?: EstablishmentAnalytics;
  back?: EstablishmentAnalytics;
  front?: EstablishmentAnalytics;
};
export type Establishment = {
  /** primary id for the establishment */
  id?: string;
  /** information about the establishment */
  description?: string;
  /** reference to the google place id used by the google maps api */
  google_place_id?: string;
  /** name of the establishment */
  name?: string;
  /** address of the establishment */
  address?: string;
  /** city of the establishment */
  city?: string;
  /** cuisine of the establishment */
  cuisine?: string;
  /** state of the establishment */
  state?: string;
  /** country of the establishment */
  country?: string;
  /** neighborhood of the establishment */
  neighborhood?: string;
  /** zipcode of the establishment */
  zipcode?: string;
  insights?: EstablishmentInsights;
  /** indicates if wage theft has been reported at the establishment */
  wage_theft_reported?: boolean;
  /** link to the wage theft report */
  wage_theft_link?: string;
  /** health score of the establishment */
  health_score?: string;
  /** type of establishment, such as 'indian restaurant' */
  type?: string;
  /** url to the google icon for the establishment */
  icon?: string;
  /** url to the cover photo for the establishment. initially taken from google. */
  photo?: string;
  location?: Location;
  /** date-time the establishment was created */
  created_at?: string;
  /** date-time the establishment was last updated */
  updated_at?: string;
};
export type JobListing = {
  /** primary id for the job listing */
  id?: string;
  /** primary id for the establishmen this job listings belongs to */
  establishmentId?: string;
  establishmentName?: string;
  establishmentAddress?: string;
  role?: string;
  roleType?: string;
  requiredSkills?: string;
  yearsOfExperience?: string;
  wageType?: string;
  wageValue?: string;
  maxWageValue?: string;
  minWageValue?: string;
  compensationDetails?: string;
  /** Status of the job listing. Possible values: unknown, active, inactive, expired */
  status?: string;
  /** Name of the site this job listing was scraped from */
  source?: string;
  sourceId?: string;
  listingTitle?: string;
  listingLink?: string;
  listingDetails?: string;
  listingExpiration?: string;
  postedAt?: string;
  createdAt?: string;
};
export type ReviewRolesResponse = {
  data?: {
    name?: string;
    position?: "front_of_house" | "back_of_house";
  }[];
  limit?: number;
  next_page?: number;
};
export type CreateUpdateReview = {
  /** primary id for the review */
  id?: string;
  /** how long a shift is */
  avg_length_of_shift?: number;
  /** how many shifts a user works in a week */
  avg_shifts_per_week?: number;
  /** how much a person makes during an hour shift */
  avg_hr_per_shift?: number;
  /** how much a person gets in tips for a shift */
  avg_tip_take?: number;
  /** how busy the establishment is */
  avg_busyness?: number;
  /** benefits of working at the establishment */
  benefits?: string[];
  /** id of the establishment in our DB */
  establishment_id?: string;
  /** if the role should be hidden */
  hide_role?: boolean;
  /** users overall rating of the establishment */
  overall_rating?: number;
  /** users comments on what its like to work in an establishment */
  review?: string;
  /** the page the review was left on */
  review_page?: number;
  /** users role in an establishment */
  role?: string;
  /** status of the review */
  status?: string;
  /** rating of schedule flexibility */
  schedule_flexibility?: number;
  /** rating of management */
  management_evaluation?: number;
  /** rating of employee satisfaction */
  employee_satisfaction?: number;
  /** year employee's final or current day */
  last_year_employed?: number;
  /** if the establishment practices tip pooling */
  tip_pool_house?: boolean;
  /** if the user agreed to the terms */
  terms_agreement?: boolean;
  /** id of the user who left the review */
  user_id?: string;
  /** how much a person makes during a shift */
  wage?: number;
  /** type of wage */
  wage_type?: string;
};
export type Review = {
  /** primary id for the review */
  id?: string;
  /** how long a shift is */
  avg_length_of_shift?: number;
  /** how many shifts a user works in a week */
  avg_shifts_per_week?: number;
  /** how much a person makes during an hour shift */
  avg_hr_per_shift?: number;
  /** how much a person gets in tips for a shift */
  avg_tip_take?: number;
  /** how busy the establishment is */
  avg_busyness?: number;
  /** benefits of working at the establishment */
  benefits?: string[];
  /** name of the establishment from google places */
  establishment_name?: string;
  /** address of the establishment from google places */
  establishment_address?: string;
  /** if of the establishment in our DB */
  establishment_id?: string;
  /** rating of employee satisfaction */
  employee_satisfaction?: number;
  /** what part of the establishment a user worked in */
  house_role?: "front" | "back" | "ignored";
  /** if the role should be hidden */
  hide_role?: boolean;
  /** users overall rating of the establishment */
  overall_rating?: number;
  /** year employee's final or current day */
  last_year_employed?: number;
  /** users comments on what its like to work in an establishment */
  review?: string;
  /** the page the review was left on */
  review_page?: number;
  /** users role in an establishment */
  role?: string;
  /** status of the review */
  status?: "draft" | "submitted" | "completed" | "exited";
  /** rating of schedule flexibility */
  schedule_flexibility?: number;
  /** rating of management */
  management_evaluation?: number;
  /** if tip pooling is enabled, which is when all the tips are collected and then redistributed across the staff */
  tip_pool_house?: boolean;
  /** clear acknowledgement that a user has agreed to the terms */
  terms_agreement?: boolean;
  /** id to the user document */
  user_id?: string;
  /** ip address used to create the review */
  user_ip_address?: string;
  /** first name of the user creating the review */
  userFirstName?: string;
  /** last name of the user creating the review */
  userLastName?: string;
  /** phone number of the user creating the review */
  userPhoneNumber?: string;
  /** email of the user creating the review */
  userEmail?: string;
  /** number of times the review has been updated */
  update_count?: number;
  /** wage the user earns at the establishment */
  wage?: number;
  /** the type of wage the user earns */
  wage_type?: "salary" | "hourly";
  /** date-time the establishment was created */
  createdAt?: string;
  /** date-time the establishment was last updated */
  updatedAt?: string;
};
export type User = {
  /** primary id for the user */
  id?: string;
  /** auth provider id used to authenticate the user */
  auth_provider_id?: string;
  /** auth provider used to authenticate the user */
  auth_provider?: string;
  /** social provider used to authenticate the user */
  social_provider?: string;
  /** country of origin for the user */
  country?: string;
  /** users current employer */
  current_employer?: string;
  /** id of an establishment that matches the currentEmployer */
  current_employer_match?: string;
  /** email address of the user */
  email_address?: string;
  /** the employment status of a user */
  employment_status?: string;
  /** first name of the user */
  first_name?: string;
  /** last name of the user */
  last_name?: string;
  /** nickname of the user */
  nickname?: string;
  /** phone number of the user */
  phone_number?: string;
  /** number of reviews a user has left */
  review_count?: number;
  /** marks if a user has reviews that have been flagged or reported */
  reviews_flagged?: boolean;
  /** users selected username */
  username?: string;
  /** zipcode of the user */
  zipcode?: string;
  /** list of ids for entered sweepstakes */
  sweepstakes_ids?: number[];
  /** list of ip addresses the user has used */
  ip_addresses?: string[];
  /** date-time the establishment was created */
  created_at?: string;
  /** date-time the establishment was last updated */
  updated_at?: string;
};
export type UpdateUser = {
  /** email address of the user */
  email_address: string;
  /** first name of the user */
  first_name: string;
  /** last name of the user */
  last_name: string;
  /** nickname of the user */
  nickname: string;
  /** phone number of the user */
  phone_number?: string;
};
export type SaveJobListing = {
  /** primary id for the job listing to save */
  job_listing_id?: string;
};
export type ViewedResource = {
  /** ID of the viewed resource */
  resource_id?: string;
  /** Type of resource that was viewed */
  resource_type?: string;
};
export type EmailContactUs = {
  /** the users name */
  name?: string;
  /** users email */
  email?: string;
  /** users message from the site */
  message?: string;
};
export type Subscription = {
  /** the users email */
  email?: string;
  /** the users email */
  subject?: string;
  /** date-time the establishment was created */
  created_at?: string;
  /** date-time the establishment was last updated */
  updated_at?: string;
};
export type Neighborhood = {
  /** primary id for the neighborhood */
  id?: string;
  /** name of the neighborhood */
  name?: string;
  /** city of the neighborhood */
  city?: string;
  /** state of the neighborhood */
  state?: string;
};
export const {
  useGetEstablishmentReviewsQuery,
  useGetPublicEstablishmentsQuery,
  useCreatePublicEstablishmentMutation,
  useGetPublicEstablishmentWithIdQuery,
  useGetPublicEstablishmentReviewsQuery,
  useGetPublicEstablishmentJobListingsQuery,
  useGetPublicEstablishmentSearchQuery,
  useGetReviewRolesQuery,
  useCreatePublicReviewMutation,
  useUpdatePublicReviewMutation,
  useGetPublicReviewWithIdQuery,
  useCreateUserMutation,
  useGetUserWithIdQuery,
  useUpdateUserMutation,
  useResetUserPasswordMutation,
  useGetUserSavedJobListingsQuery,
  useDeleteUserSavedJobListingsMutation,
  usePostUserSavedJobListingsMutation,
  useMarkResourceAsViewedMutation,
  useGetUserReviewsQuery,
  useCreateContactUsMutation,
  useCreateSubscriptionMutation,
  useGetPublicEstablishmentNeighborhoodsQuery,
} = injectedRtkApi;
