import { useAuth0 } from "@auth0/auth0-react";
import { Box, CircularProgress, Container, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CreateUserApiArg, useCreateUserMutation, useUpdatePublicReviewMutation } from "../../store/kcApi";
import { RootState } from "../../store/store";
import { LocalReview } from "../../utils/helpers";
import NoiseAnimatedBackground from "../ExplorePage/components/NoiseAnimatedBackground";

const AuthRedirectPage: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [addUser] = useCreateUserMutation();
  const [updateReview] = useUpdatePublicReviewMutation();
  const params = new URLSearchParams(window.location.search);
  const redirectUri = params.get("redirectUri");


  useEffect(() => {
    if (isAuthenticated && !isLoading && user !== undefined && currentUser !== null) {
      // try posting the user
      const userIdInfo = user.sub !== undefined ? user.sub.split("|") : ["unknown"];
      // post to api for user signing in, post will return the user either created or new
      let userVals: CreateUserApiArg = {
        user: {
          auth_provider_id: user.sub,
          social_provider: userIdInfo[0],
          auth_provider: "auth0",
          first_name: user.given_name,
          last_name: user.family_name,
          email_address: user.email,
          nickname: user.nickname,
        }
      };

      addUser(userVals)
        .unwrap()
        .then((payload) => {

          // check local storage for existing reviews and update them with the new user id
          const reviewsJsonString = localStorage.getItem("kc_reviews");
          const existingReviews = reviewsJsonString ? JSON.parse(reviewsJsonString) : [];

          if (existingReviews.length === 0) {
            window.location.replace(redirectUri == null ? `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/` : redirectUri);
            return;
          }

          const updatePromises = existingReviews.map((review: LocalReview) => {
            return updateReview({
              reviewId: review.id,
              createUpdateReview: {
                user_id: payload.id,
              }
            }).unwrap();
          });

          // Wait for all updateReview promises to complete
          Promise.all(updatePromises)
            .then((results) => {
              window.location.replace(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${existingReviews[0].estId}/new-review/${existingReviews[0].id}`)
            })
            .catch((error) => {
              // Handle errors if any of the promises rejected
              console.error("Error updating reviews:", error);
              window.location.replace(redirectUri == null ? `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/` : redirectUri);
            });
        })
        .catch((error) => {
          console.error("Error creating user:", error);
          window.location.replace(redirectUri == null ? `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/` : redirectUri);
        });
    }
  }, [isAuthenticated, isLoading, currentUser, addUser, redirectUri, updateReview, user]);

  return (
    <Box sx={{ backgroundColor: "#FBF8F5", position: 'relative', zIndex: 0 }}>
      <NoiseAnimatedBackground />
      <Container sx={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Box sx={{ display: "flex", justifyContent: "center", height: "60vh" }}>
          <Stack
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" variant="indeterminate" />
            <Typography variant='body1' color='text.secondary' align='center' sx={{ padding: '60px', fontWeight: 'bold' }}>Hang tight, warming up the grill...</Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthRedirectPage;
